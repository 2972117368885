export const setListData = (data) => {
    return (dispatch) => {
        dispatch({
            type: "SET_DATA",
            payload: data
        })
    }
}

export const checkRow = (data) => {
    return (dispatch) => {
        dispatch({
            type: "CHECK_ROW",
            payload: data
        })
    }
}

export const unCheckRow = (data) => {
    return (dispatch) => {
        dispatch({
            type: "UNCHECK_ROW",
            payload: data
        })
    }
}

export const checkAllRows = (data) => {
    return (dispatch) => {
        dispatch({
            type: "CHECK_ALL_ROWS",
            payload: data
        })
    }
}