import React, { useState } from "react";
import { Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory, useParams } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import { useSelector } from "react-redux";

const Update = () => {
    let data = useSelector(state => state.listData);
    if (data.length === 0)
        data = JSON.parse(sessionStorage.getItem("listData"));
    const { id } = useParams();
    const dataSingle = data.filter(obj => obj.id === id);

    const { http } = AuthUser();
    const [validity, setValidity] = useState(dataSingle[0].validity);
    const [expireDate, setExpireDate] = useState(dataSingle[0].expire_date ? dataSingle[0].expire_date.slice(0, 10) : "");
    const [status, setStatus] = useState(dataSingle[0].is_active);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const history = useHistory();

    const setStatusToState = (e) => {
        e.persist();
        setStatus(e.target.checked ? "1" : "0");
    }

    const deleteCoupon = () => {
        const resp = window.confirm("Do you want to delete this coupon?");
        if (resp) {
          setDeleteError(null);
          setDeleteLoading(true);
          http.post(Apis.coupon.delete, {coupon_uuid: id})
          .then(res => {
            setDeleteLoading(false);
            setTimeout(() => {
              history.goBack();
            }, 500);
          })
          .catch(err => {
            setDeleteLoading(false);
            if (err.response.status === 400) {
              const errorMsg = parseError(err.response.data.error);
              setDeleteError(errorMsg);
            } else {
              setDeleteError([err.response.data.message]);
            }
          });
        }
      }

    const handleUpdate = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        
        http.post(Apis.coupon.update, { coupon_uuid: id, is_active: status, expire_date: expireDate, validity: validity })
        .then(res => {
            setLoading(false);
            setSuccess("Coupon updated successfully!");
            setTimeout(() => {
                history.goBack();
            }, 800);
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> COUPON <span style={{ fontSize: '12px', opacity: 0.5 }}>Update</span></h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            {
                deleteError && <ErrorMsg errors={deleteError} />
            }
            <Form className="mt-4" onSubmit={ handleUpdate }>
                <Form.Group id="code" className="mb-4">
                    <Form.Label>Code</Form.Label>
                    <Form.Control value={ dataSingle[0].code } readOnly />
                </Form.Group>
                <Form.Group id="is_active" className="mb-4">
                    <Form.Label>Is Active</Form.Label><br />
                    <span style={{ cursor: 'pointer' }}>
                        <div className="d-inline-block me-1">OFF</div>
                        <div className="form-check form-switch d-inline-block">
                            <input type="checkbox" className="form-check-input" id="is_active_toggle" defaultChecked={ status } onChange={ setStatusToState } style={{ cursor: 'pointer' }} />
                            <label htmlFor="is_active_toggle" className="form-check-label">ON</label>
                        </div>
                    </span>
                </Form.Group>
                <Form.Group id="expire_date" className="mb-4">
                    <Form.Label>Expire Date</Form.Label>
                    <Form.Control type="date" defaultValue={ expireDate } onChange={(e) => setExpireDate(e.target.value)} />
                </Form.Group>
                <Form.Group id="validity" className="mb-4">
                    <Form.Label>Days Validity</Form.Label>
                    <Form.Control type="number" min="1" defaultValue={ validity } onChange={(e) => setValidity(e.target.value)} />
                </Form.Group>

                <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} disabled={loading}>
                    UPDATE {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-danger ms-1" style={{ fontSize: '12px', fontWeight: 'bold' }} onClick={ deleteCoupon }>
                    DELETE {deleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ () => history.goBack() }>CANCEL</button>
            </Form>
        </div>
    );
}
export default Update;