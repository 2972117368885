
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import AuthUser from "../components/AuthUser";

import { Routes } from "../routes";


const ChangePassword = () => {
    const {http, user, getToken} = AuthUser();
    if(!getToken()){
      window.location.href = Routes.Signin.path;
    }
    const [password, setPassword] = useState();
    const [confirm_password, setConfirmPassword] = useState();
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setIsPending(true);
        
        if(password !== confirm_password){
            setError(['Password and Confirm Password field must be same!']);
            setIsPending(false);
        }
        else{
            // api call
            http.post(Apis.change_password, { user_uuid: user.id, password: password })
            .then(res => {
                setIsPending(false);
                setPasswordChangeSuccess(res.data.message);
                setTimeout(() => {
                  window.location.reload();
                }, 800); 
            })
            .catch(err => {
                setIsPending(false);
                if (err.response.status === 400) {
                  const errorMsg = parseError(err.response.data.error);
                  setError(errorMsg);
                } else {
                  setError([err.response.data.message]);
                }          
            });
        }
    }





  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Index.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to Home
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Change password</h3>
                {
                    passwordChangeSuccess && <div className="row mt-3">
                                        <div className="col">
                                            <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                                <strong>Success!</strong> {passwordChangeSuccess}
                                            </div>
                                        </div>
                                    </div>
                }
                {
                    error && <ErrorMsg errors={error} />
                }
                <Form onSubmit={ handleSubmit }>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Confirm Password" onChange={(e)=>setConfirmPassword(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <button type="submit" className="btn cust-button w-100" disabled={isPending}>
                    Update {isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default ChangePassword;