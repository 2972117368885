import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { Form } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { checkAllRows } from "./state/action-creators";

const Table = ({ columns, data, fetchData, loading, pageCount: controlledPageCount, loadData = true }) => {
    const dispatch = useDispatch();
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      // Get the state from the instance
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 20 }, // Pass our hoisted table state
        manualPagination: true, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount,
        autoResetSelectedRows: false,
        getRowId: (row) => row.key
      },
      usePagination
    );

    const selectAllRows = (e) => {
      const checkBoxes = document.getElementsByName("row[]");
      let rowIds = [];
  
      if (e.target.checked) {
        for (let i = 0, l = checkBoxes.length; i < l; i++) {
          checkBoxes[i].checked = true;
          rowIds.push(checkBoxes[i].value);
        }
      } else {      
        for (let i = 0, l = checkBoxes.length; i < l; i++) {
          checkBoxes[i].checked = false;
        }
      }
      dispatch(checkAllRows(rowIds));
    }

    const [searchText, setSearchText] = useState(null);
    const [colName, setColName] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const setTextToState = (e, colName) => {
      if (e.target.value !== "") {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          setColName(colName);
        }
      } else {
        setSearchText(e.target.value);
        setColName(colName);
      }
    }

    const setStatusToState = (status, colName) => {
      setSearchText(status);
      setColName(colName);
    }

    const setValuesToState = (colName, sortOrder) => {
      setColName(colName);
      setSortOrder(sortOrder);
    }

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
      if (loadData) {
        fetchData({ pageIndex, pageSize, searchText, colName, sortOrder });
      }
    }, [fetchData, pageIndex, pageSize, searchText, colName, sortOrder]);
  
    // Render the UI for your table
    return (
      <>                
        <div className="table-responsive">        
          <table className="table table-light table-bordered table-striped table-hover" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th style={{ backgroundColor: '#eee', verticalAlign: 'middle' }} {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {
                        column.checkAll ? <input type="checkbox" className="form-check-input" onChange={ (e) => selectAllRows(e) } style={{ cursor: 'pointer' }} /> : ""
                      }
                      <span>
                        {
                          column.isSorted
                          ? (colName && sortOrder)
                            ? (column.id === colName)
                              ? (sortOrder === "desc")
                                ? <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "asc")}> 🔽</span>
                                : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "desc")}> 🔼</span>
                              : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "asc")}> 🔽</span>
                            : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "asc")}> 🔽</span>
                          : ""
                        }
                      </span>
                      {
                        column.hasSearch
                        ? (column.Header !== "Is Active")
                          ? <Form.Control type="text" onKeyUp={(e) => setTextToState(e, column.id)} />
                          : (
                              <Form.Select onChange={(e) => setStatusToState(e.target.value, column.id)}>
                                <option defaultValue value="">Select...</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>                
                              </Form.Select>
                            )
                        : ""
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="text-center" style={{ verticalAlign: 'middle' }} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000"><span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span></td>
                ) : (
                  <td colSpan="10000">
                    Showing {page.length} of ~{controlledPageCount * pageSize}{" "}
                    results
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className="row">
          <div className="pagination">
            <div className="col-sm-6">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>&nbsp;&nbsp;
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
              </button>&nbsp;&nbsp;
              <span>
                Page&nbsp;
                <strong>
                  {pageIndex + 1} / {pageOptions.length}
                </strong>&nbsp;&nbsp;
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>&nbsp;&nbsp;
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
              </button>&nbsp;&nbsp;&nbsp;&nbsp;              
            </div>            
          </div>
        </div>
      </>
    );
}
export default Table;