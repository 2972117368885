import React, { useState } from "react";
import { Container, Row, Col, Form, FormCheck, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Apis } from "../common/Apis";
import ErrorMsg from "../common/ErrorMsg";
import BgImage from "../assets/img/login_bg.png";
import PTLogo from "../assets/img/logo.png";
import AuthUser from '../components/AuthUser';

export default () => {
    const {http, saveToken, getToken} = AuthUser();
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [consentGiven, setConsentGiven] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const history = useHistory();

    const handleSendOtp = (e) => {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);
    
        // API call to send OTP
        http.post(Apis.account_deletion_request, { email })
          .then(res => {
            setIsSubmitting(false);
            if (res.data.status == "SUCCESS" ) {
                setOtpSent(true);
                alert('OTP has been sent to your email.');
            } else {
                const errorMsg = res?.data?.message || "Something went wrong. Please try again.";
                setError(errorMsg);
            }
          })
          .catch(err => {
            setIsSubmitting(false);
            const errorMsg = err.response?.data?.message || "Something went wrong. Please try again.";
            setError(errorMsg);
          });
      };
    
      const handleDelete = (e) => {
        e.preventDefault();
        if (!consentGiven) {
          setError("Please agree to the consent by checking the box.");
          return;
        }
        
        if (otp.length != 6) {
            setError("Wrong OTP, please try again.");
            return;
          }

        setIsSubmitting(true);
        setError(null);
    
        // API call to confirm deletion
        http.post(Apis.account_confirm_delete, { email, otp_code: otp })
          .then(res => {
            setIsSubmitting(false);
            if (res.data.status == "SUCCESS" ) {
                alert('Your account has been deleted.');
                history.push("/"); // Redirect after deletion
            } else {
                const errorMsg = res?.data?.message || "Something went wrong. Please try again.";
                setError(errorMsg);
            }
          })
          .catch(err => {
            setIsSubmitting(false);
            const errorMsg = err.response?.data?.message || "Something went wrong. Please try again.";
            setError(errorMsg);
          });
      };

    return (
        <main className="bg-light">
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <div className="text-center mb-4">
            <img src={PTLogo} height={21} alt="PeerTracks Logo" />
          </div>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white form-container shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                {error && <ErrorMsg errors={[error]} />}
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Account Deletion Consent</h3>
                </div>
                {!otpSent ? (
                  <Form className="mt-4" onSubmit={handleSendOtp}>
                    <Form.Group id="email" className="form-group">
                      <InputGroup>
                        <Form.Control
                          required
                          type="email"
                          id="email"
                          placeholder="Enter your email address"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          className="form-control"
                        />
                      </InputGroup>
                    </Form.Group>
                    
                    <Button type="submit" className="mt-4 w-100 btn-danger" disabled={isSubmitting}>
                      Send OTP {isSubmitting && <span className="spinner-border"></span>}
                    </Button>
                  </Form>
                ) : (
                  <Form className="mt-4" onSubmit={handleDelete}>
                    <Form.Group id="otp" className="form-group">
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          id="otp"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={e => setOtp(e.target.value)}
                          className="form-control"
                        />
                      </InputGroup>
                      <Form.Group className="form-group">
                        <Form.Check type="checkbox">
                            <FormCheck.Input
                            id="consentCheckbox"
                            checked={consentGiven}
                            onChange={e => setConsentGiven(e.target.checked)}
                            className="form-check-input"
                            />
                            <FormCheck.Label htmlFor="consentCheckbox" className="form-check-label">
                            I understand that deleting my account is permanent and cannot be undone.
                            </FormCheck.Label>
                        </Form.Check>
                        </Form.Group>
                    </Form.Group>
                    <Button type="submit" className="mt-4 w-100 btn-danger" disabled={isSubmitting}>
                      Delete My Account {isSubmitting && <span className="spinner-border"></span>}
                    </Button>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
