
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faMusic, faUser, faListUl } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Routes } from "../routes";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";

export default () => {
  const { http, getToken, logout } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    setError(null);
    setIsPending(true);
    http.get(Apis.token_validity)
    .then(res => {
        // Token is valid            
        http.get(Apis.dashboard_count)
        .then(res => {
            setIsPending(false);
            setCounts(res.data.data);
        })
        .catch(err => {
            setIsPending(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);          
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    })
    .catch(err => {
        setIsPending(false);
        if (err.response.status === 400) {
            logout();   // Force logout
        } else {
            setError([err.response.data.message]);
        }
    });
  }, []);

  return (
        <>
            <div className="row mt-4">
                {
                    error && <ErrorMsg errors={error} />
                }
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat purple-plum">
                        <div className="visual">
                            <span><FontAwesomeIcon icon={ faCog } /></span>
                        </div>
                        <div className="details">
                            <div className="number">
                                { isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span> }
                                { counts && counts.category_count }
                            </div>
                            <div className="desc">
                                Categories
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat blue-madison">
                        <div className="visual">
                            <span><FontAwesomeIcon icon={ faMusic } /></span>
                        </div>
                        <div className="details">
                            <div className="number">
                                { isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span> }
                                { counts && counts.song_count }
                            </div>
                            <div className="desc">
                                Songs
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat red-intense">
                        <div className="visual">
                            <span><FontAwesomeIcon icon={ faUser } /></span>
                        </div>
                        <div className="details">
                            <div className="number">
                                { isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span> }
                                { counts && counts.artist_count }
                            </div>
                            <div className="desc">
                                Artists
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat green-haze">
                        <div className="visual">
                            <span><FontAwesomeIcon icon={ faListUl } /></span>
                        </div>
                        <div className="details">
                            <div className="number">
                                { isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span> }
                                { counts && counts.playlist_count }
                            </div>
                            <div className="desc">
                                Playlists
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
