export const Routes = {
    // App Routes
    Home: { path: "/" },
    Signin: { path: "/login" },
    ChangePassword: { path: "/change-password" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Forbidden: { path: "/403" },
    AccountDeletion: { path: "/account-deletion" },

    // Menu
    Index: { path: "/index" },
    Category: {
        List: { path: "/category/index" },
        Create: { path: "/category/create" },
        Update: { path: "/category/update/:id" }
    },
    Artist: {
        List: { path: "/music-artist/index" },
        Create: { path: "/music-artist/create" },
        Update: { path: "/music-artist/update/:id" }
    },
    HomePlaylist: {
        List: { path: "/home-playlist/index" },
        Create: { path: "/home-playlist/create" },
        Update: { path: "/home-playlist/update/:id" }
    },
    Song: {
        List: { path: "/music-song/index" },
        Create: { path: "/music-song/create" },
        Update: { path: "/music-song/update/:id" }
    },
    Playlist: {
        List: { path: "/music-playlist/index" },
        Create: { path: "/music-playlist/create" },
        Update: { path: "/music-playlist/update/:id" }
    },
    Banner: {
        List: { path: "/banner/index" },
        Create: { path: "/banner/create" },
        Update: { path: "/banner/update/:id" }
    },
    SongDuration: {
        List: { path: "/song-duration/index" },
    },
    Radio: {
        List: { path: "/radio/index" },
        Create: { path: "/radio/create" },
    },
    Coupon: {
        List: { path: "/coupon/index" },
        Create: { path: "/coupon/create" },
        Update: { path: "/coupon/update/:id" },
        BulkCreate: { path: "/coupon/bulk_create" },
    },
    ExternalVideo: {
        List: { path: "/external-video/index" },
        Create: { path: "/external-video/create" },
        Update: { path: "/external-video/update/:id" },
    },
    Setting: {
        List: { path: "/setting/index" }
    }
};