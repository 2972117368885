import React, { useEffect, useState } from "react";
import { Form, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import Select from "react-select";

const Create = () => {
    const { http } = AuthUser();
    const [title, set_title] = useState(null);
    const [is_active, set_is_active] = useState(null);
    const [description, set_description] = useState(null);
    const [embed_link, set_embed_link] = useState(null);
    const [iframe_link, set_iframe_link] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [status, setStatus] = useState("0");
    const [desp, setDesp] = useState(null);
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();
    const [music_playlists, setMusicPlayLists] = useState(null);
    const [music_playlist, setMusicPlayList] = useState(null);

    useEffect(() => {
        // http.get(Apis.get_all_songs)
        // .then(res => {
        //     setMusicPlayLists(res.data.data);
        // });
    }, []);

  
    const setStatusToState = (e) => {
        e.persist();
        setStatus(e.target.checked ? "1" : "0");
    }

    const handleSave = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('is_active', is_active == true  ? 1 : 0);
        formData.append('embed_link', embed_link);
        formData.append('iframe_link', iframe_link);
        const config = {
            headers: {
                // 'content-type': 'multipart/form-data'
            }
        };
        
        http.post(Apis.external_video.create, formData, config)
        .then(res => {
            console.log(res.data);
            setLoading(false);
            if(res.data.status == 'ERROR') {
                setError([res.data.data]);
            } else {
                setSuccess("3dvista VR Room Link added successfully!");
                setTimeout(() => {
                    history.goBack();
                }, 800);
            }
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> 3dvista VR Room <span style={{ fontSize: '12px', opacity: 0.5 }}>Create</span></h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            <Form className="mt-4" onSubmit={handleSave}>

                <Form.Group id="name" className="mb-4">
                    <Form.Label>Title<span style={{ color: "red" }}> *</span></Form.Label>
                    <Form.Control required onChange={(e) => set_title(e.target.value)} />
                </Form.Group>

                <Form.Group id="description" className="mb-4">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} onChange={(e) => set_description(e.target.value)} />
                </Form.Group>

                <Form.Group id="isActive" className="mb-4">
                    <Form.Check 
                        type="checkbox" 
                        label="Is Active" 
                        onChange={(e) => set_is_active(e.target.checked)} 
                    />
                </Form.Group>

                <Form.Group id="embedLink" className="mb-4">
                    <Form.Label>Embedded Link<span style={{ color: "red" }}> *</span></Form.Label>
                    <Form.Control required onChange={(e) => set_embed_link(e.target.value)} />
                </Form.Group>

                <Form.Group id="iframeLink" className="mb-4">
                    <Form.Label>IFrame Link<span style={{ color: "red" }}> *</span></Form.Label>
                    <Form.Control required onChange={(e) => set_iframe_link(e.target.value)} />
                </Form.Group>

                <button className="btn cust-button" 
                    style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} 
                    disabled={loading}
                >
                    CREATE {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>

                <button 
                    type="button" 
                    className="btn btn-default ms-1" 
                    style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} 
                    onClick={() => history.goBack()}
                >
                    CANCEL
                </button>

                </Form>

        </div>
    );
}
export default Create;