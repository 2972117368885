import React, { useState } from "react";
import { Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";

const BulkCreate = () => {
    const { http } = AuthUser();
    const [noOfCoupons, setNoOfCoupons] = useState("1");    
    const [status, setStatus] = useState("0");
    const [expireDate, setExpireDate] = useState("");
    const [validity, setValidity] = useState("1");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    const setStatusToState = (e) => {
        e.persist();
        setStatus(e.target.checked ? "1" : "0");
    }

    const handleBulkSave = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        
        http.post(Apis.coupon.coupon_create_multiple, { no_of_coupons: noOfCoupons, is_active: status, expire_date: expireDate, validity: validity })
        .then(res => {
            setLoading(false);
            setSuccess(res.data.message);
            setTimeout(() => {
                history.goBack();
            }, 800);
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> COUPON <span style={{ fontSize: '12px', opacity: 0.5 }}>Create In Bulk</span></h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            <Form className="mt-4" onSubmit={ handleBulkSave }>
                <Form.Group id="no_of_coupons" className="mb-4">
                    <Form.Label>Number of coupons</Form.Label>
                    <Form.Control type="number" min="1" defaultValue="1" onChange={(e) => setNoOfCoupons(e.target.value)} />
                </Form.Group>                
                <Form.Group id="is_active" className="mb-4">
                    <Form.Label>Is Active</Form.Label><br />
                    <span style={{ cursor: 'pointer' }}>
                        <div className="d-inline-block me-1">OFF</div>
                        <div className="form-check form-switch d-inline-block">
                            <input type="checkbox" className="form-check-input" id="is_active_toggle" onChange={ setStatusToState } style={{ cursor: 'pointer' }} />
                            <label htmlFor="is_active_toggle" className="form-check-label">ON</label>
                        </div>
                    </span>
                </Form.Group>
                <Form.Group id="expire_date" className="mb-4">
                    <Form.Label>Expire Date</Form.Label>
                    <Form.Control type="date" onChange={(e) => setExpireDate(e.target.value)} />
                </Form.Group>
                <Form.Group id="validity" className="mb-4">
                    <Form.Label>Days Validity</Form.Label>
                    <Form.Control type="number" min="1" defaultValue="1" onChange={(e) => setValidity(e.target.value)} />
                </Form.Group>

                <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} disabled={loading}>
                    CREATE IN BULK {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ () => history.goBack() }>CANCEL</button>
            </Form>
        </div>
    );
}
export default BulkCreate;