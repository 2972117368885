
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import PTLogo from "../assets/img/logo.png";

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-dark flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={PTLogo} height={40} />
    </div>
  );
};
