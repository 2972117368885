import React, { useState, useRef, useMemo, useCallback } from "react";
import Table from "../../Table";
import { Button, Dropdown, Modal, Nav } from '@themesberg/react-bootstrap';
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faList, faPencilAlt, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setListData, checkRow, unCheckRow } from "../../state/action-creators/index";

const List = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.listData);  
  const rowIds = useSelector(state => state.rowIds);

  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const setRowIdsToState = (e) => {
    if (e.target.checked) {
      dispatch(checkRow(e.target.value));
    } else {
      dispatch(unCheckRow(e.target.value));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "key",
        Cell: ({ row: { original } }) => (
          <>
            <input type="checkbox" className="form-check-input" name="row[]" value={ original.id } onChange={(e) => setRowIdsToState(e)} style={{ cursor: 'pointer' }} />            
          </>            
        ),
        checkAll: true
      },
      {
        Header: "Track Title",
        accessor: "name",
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Img Thumb",
        accessor: "img_thumb",
        Cell: ({ row: { original } }) => (
          <>
            { original.img_thumb && <img src={original.img_thumb} width="100" alt="Img Thumb" /> }            
          </>            
        ),
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Song File",
        accessor: "song_file",
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Song Url",
        accessor: "song_url",
        Cell: ({ row: { original } }) => (
          <>
            {original.song_url && (
                <>
                  <a 
                    href={original.song_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {original.song_url.length > 15 
                      ? `${original.song_url.slice(0, 15)}...` 
                      : original.song_url
                    }
                  </a>
                </>
              )}          
          </>            
        ),
        isSorted: false,
        hasSearch: false
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span title="View" style={{ cursor: 'pointer', marginRight: '4px' }} onClick={() => viewSong(original)}><FontAwesomeIcon icon={ faEye } /></span>
            <Link to={`${ Routes.Song.Update.path.slice(0, 18) }/${ original.id }`}><span title="Update" style={{ cursor: 'pointer', marginRight: '4px' }}><FontAwesomeIcon icon={ faPencilAlt } /></span></Link>
            <span title="Delete" style={{ cursor: 'pointer' }} onClick={ () => deleteSong(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span>            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showViewSong, setShowViewSong] = useState(false);
  const [songData, setSongData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null); 

  const handleViewSongClose = () => {
    setSongData(null);
    setShowViewSong(false);
  }

  const viewSong = (data) => {
    setSongData(data);
    setShowViewSong(true);
  }

  const deleteSong = (id) => {
    const resp = window.confirm("Do you want to delete this song?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.song.delete, {song_uuid: id})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    }
  }

  const deleteAll = () => {
    if (rowIds.length === 0) {
      setDeleteError(["Please select a song to proceed!"]);
      return false;
    }
    const resp = window.confirm("Do you want to delete selected songs?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.song.deleteAll, {song_uuids: rowIds})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    } else {
      setDeleteError(null);
    }
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.song.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText && colName) {
      apiString += `&search_keyword=${searchText}&search_column=${colName}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        dispatch(setListData(res.data.data.data));
        sessionStorage.setItem("listData", JSON.stringify(res.data.data.data));
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">          
          <div className="col-sm-6 mt-3">
            <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> MUSIC SONG <span style={{ fontSize: '12px', opacity: 0.5 }}>Index</span></h6>
          </div>
          <div className="col-sm-6 text-right mt-3">
            
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mt-2">
            <Link to={ Routes.Song.Create.path }><button title="Create" className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faPlus } /></span></button></Link>
            <Dropdown as={Nav.Item} className="d-inline-block">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <button className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }}>ACTIONS</button>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <span role="button" onClick={ deleteAll }>
                    <FontAwesomeIcon icon={faTrashAlt} className="text-danger me-2" /> Delete All
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-sm-6 text-right mt-2">
            
          </div>
        </div>        
        {
            error && <ErrorMsg errors={error} />
        }
        {
            deleteError && <ErrorMsg errors={deleteError} />
        }
        {
            deleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>      

      <Modal as={Modal.Dialog} centered show={ showViewSong } onHide={ handleViewSongClose }>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Music Song Details</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={ handleViewSongClose } />
        </Modal.Header>
        <Modal.Body>
          {
            songData &&
            <div className="table-responsive">
              <table className="table table-light table-bordered table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Track Title</td>
                    <td>{ songData.name }</td>
                  </tr>
                  <tr>
                    <td>Img Thumb</td>
                    <td>{ <img src={songData.img_thumb} width="200" alt="Img Thumb" /> }</td>
                  </tr>
                  <tr>
                    <td>Img Banner</td>
                    <td>{ <img src={songData.img_banner} width="200" alt="Img Banner" /> }</td>
                  </tr>
                  <tr>
                    <td>Song File</td>
                    <td>{ songData.song_file }</td>
                  </tr>
                  <tr>
                    <td>Song Url</td>
                    <td>{ songData.song_url }</td>
                  </tr>
                  <tr>
                    <td>360 Audio File</td>
                    <td>{ songData.xr_audio_file }</td>
                  </tr>
                  <tr>
                    <td>360 Audio File Url</td>
                    <td>{ songData.xr_audio_url }</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{ songData.description || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Album Name</td>
                    <td>{ songData.album_name || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Recording Label</td>
                    <td>{ songData.label }</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{ songData.country }</td>
                  </tr>
                  <tr>
                    <td>Disk Number</td>
                    <td>{ songData.disc_num || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Track Number</td>
                    <td>{ songData.track_num || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>BPM</td>
                    <td>{ songData.bpm || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Contract ID</td>
                    <td>{ songData.contract_id }</td>
                  </tr>
                  <tr>
                    <td>External ID</td>
                    <td>{ songData.external_id }</td>
                  </tr>
                  <tr>
                    <td>Contact Email</td>
                    <td>{ songData.contact_email }</td>
                  </tr>
                  <tr>
                    <td>Content</td>
                    <td>{ songData.content || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>{ songData.price || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Duration</td>
                    <td>{ songData.duration || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Release Date</td>
                    <td>{ songData.release_date ? songData.release_date.slice(0, 10) : <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>                  
                  <tr>
                    <td>Is 360 Audio</td>
                    <td>{ songData.is_xr === 1 && songData.is_video === 0 ? 1 : 0 }</td>
                  </tr>
                  <tr>
                    <td>Is Active</td>
                    <td>{ songData.is_active }</td>
                  </tr>
                  <tr>
                    <td>Is New</td>
                    <td>{ songData.is_new }</td>
                  </tr>
                  <tr>
                    <td>Is Top</td>
                    <td>{ songData.is_top }</td>
                  </tr>
                  <tr>
                    <td>Is Hot</td>
                    <td>{ songData.is_hot }</td>
                  </tr>
                  <tr>
                    <td>Is Video</td>
                    <td>{ songData.is_video }</td>
                  </tr>
                  <tr>
                    <td>Is Explicit</td>
                    <td>{ songData.is_explicit }</td>
                  </tr>
                  <tr>
                    <td>Lang</td>
                    <td>{ songData.lang || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{ songData.type || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{ songData.status || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Mood</td>
                    <td>{ songData.mood || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Count Views</td>
                    <td>{ songData.count_views || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Count Likes</td>
                    <td>{ songData.count_likes || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Count Purchase</td>
                    <td>{ songData.count_purchase || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Count Rates</td>
                    <td>{ songData.count_rates || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Rates</td>
                    <td>{ songData.rates || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Created User</td>
                    <td>{ songData.created_user || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Updated User</td>
                    <td>{ songData.updated_user || <em style={{ color: 'red' }}>(not set)</em> }</td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>{ songData.created_at }</td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>{ songData.updated_at }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {
            songData &&
            <Link to={`${ Routes.Song.Update.path.slice(0, 18) }/${ songData.id }`}>
              <button className="btn cust-button" style={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>EDIT</button>
            </Link>
          }
          <button className="btn btn-default" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ handleViewSongClose }>CLOSE</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default List;