import React, { useState } from "react";
import { Form, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";

const Create = () => {
    const { http } = AuthUser();
    const [name, setName] = useState(null);
    const [thumbImage, setThumbImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);    
    const [realName, setRealName] = useState(null);
    const [desp, setDesp] = useState("");
    const [content, setContent] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [status, setStatus] = useState("0");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    const loadImage = (e, imgId) => {
        const output = document.getElementById(imgId);
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = () => {
          URL.revokeObjectURL(output.src); // free memory
        }
        if (imgId === "img_thumb_preview")
            setThumbImage(e.target.files[0]);
        else if (imgId === "img_banner_preview")
            setBannerImage(e.target.files[0]);
    }

    const setStatusToState = (e) => {
        e.persist();
        setStatus(e.target.checked ? "1" : "0");
    }

    const handleSave = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('img_thumb', thumbImage);
        formData.append('img_banner', bannerImage);
        formData.append('real_name', realName);        
        formData.append('description', desp);
        formData.append('content', content);
        formData.append('birth_date', birthDate);
        formData.append('is_active', status);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        http.post(Apis.artist.create, formData, config)
        .then(res => {
            setLoading(false);
            setSuccess("Artist created successfully!");
            setTimeout(() => {
                history.goBack();
            }, 800);
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> MUSIC ARTIST <span style={{ fontSize: '12px', opacity: 0.5 }}>Create</span></h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            <Form className="mt-4" onSubmit={ handleSave }>
                <Form.Group id="name" className="mb-4">
                    <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
                    <Form.Control required onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group id="img_thumb" className="mb-4">
                    <Form.Label>Img Thumb</Form.Label>
                    <div className="jumbotron">
                        <Image id="img_thumb_preview" src="#" width="200" alt="Drag & drop files here …" />
                    </div>
                    <Form.Control type="file" accept="image/*" onChange={ (e) => loadImage(e, 'img_thumb_preview') } />
                </Form.Group>
                <Form.Group id="img_banner" className="mb-4">
                    <Form.Label>Img Banner</Form.Label>
                    <div className="jumbotron">
                        <Image id="img_banner_preview" src="#" width="200" alt="Drag & drop files here …" />
                    </div>
                    <Form.Control type="file" accept="image/*" onChange={ (e) => loadImage(e, 'img_banner_preview') } />
                </Form.Group>
                <Form.Group id="real_name" className="mb-4">
                    <Form.Label>Real Name</Form.Label>
                    <Form.Control onChange={(e) => setRealName(e.target.value)} />
                </Form.Group>                
                <Form.Group id="description" className="mb-4">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="4" onChange={(e) => setDesp(e.target.value)} />
                </Form.Group>
                <Form.Group id="content" className="mb-4">
                    <Form.Label>Content</Form.Label>
                    <Form.Control as="textarea" rows="4" onChange={(e) => setContent(e.target.value)} />
                </Form.Group>
                <Form.Group id="birth_date" className="mb-4">
                    <Form.Label>Birth Date</Form.Label>
                    <Form.Control type="date" onChange={(e) => setBirthDate(e.target.value)} />
                </Form.Group>
                <Form.Group id="is_active" className="mb-4">
                    <Form.Label>Is Active</Form.Label><br />
                    <span style={{ cursor: 'pointer' }}>
                        <div className="d-inline-block me-1">OFF</div>
                        <div className="form-check form-switch d-inline-block">
                            <input type="checkbox" className="form-check-input" id="is_active_toggle" onChange={ setStatusToState } style={{ cursor: 'pointer' }} />
                            <label htmlFor="is_active_toggle" className="form-check-label">ON</label>
                        </div>
                    </span>
                </Form.Group>

                <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} disabled={loading}>
                    CREATE {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ () => history.goBack() }>CANCEL</button>
            </Form>
        </div>
    );
}
export default Create;