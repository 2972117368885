
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container, ListGroup } from '@themesberg/react-bootstrap';

import AuthUser from './AuthUser';
import { Link } from "react-router-dom";
import { Routes } from "../routes";

export default (props) => {
  const {token, user, logout} = AuthUser();
  const handleLogout = () => {
      if(token !== undefined){
          logout();
      }
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 cust-navbar">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center"></div>
          <Nav className="align-items-center">            
            <Dropdown as={Nav.Item}>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={ user.profile_photo ? user.profile_photo : `${process.env.PUBLIC_URL}/default_user.jpg` } className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{ user ? user.name : '' }</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Link to={Routes.ChangePassword.path} className="fw-bold dropdown-item">
                  <FontAwesomeIcon icon={faKey} className="me-2" /> Change Password
                  </Link>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <span role="button" onClick={ handleLogout }>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
