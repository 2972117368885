
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes, faHome, faList, faTicketAlt, faUser, faMusic, faArrowsAltH, faCopy, faListAlt, faListUl, faBell, faCog, faChalkboard, faPhotoVideo, faAssistiveListeningSystems, faServer } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import PTLogo from "../assets/img/logo.png";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    let navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Index.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-3 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ReactHero} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Image src={PTLogo} className="logo" />
              <NavItem title="Home" link={Routes.Index.path} icon={faHome} />
              <NavItem title="Category" link={Routes.Category.List.path} icon={faList} />
              <NavItem title="Coupon" link={Routes.Coupon.List.path} icon={faTicketAlt} />
              <NavItem title="Artist" link={Routes.Artist.List.path} icon={faUser} />
              <NavItem title="Music Playlist" link={Routes.Playlist.List.path} icon={faCopy} />
              <NavItem title="Music Song" link={Routes.Song.List.path} icon={faMusic} />
              <NavItem title="Song Duration" link={Routes.SongDuration.List.path} icon={faArrowsAltH} />
              <NavItem title="Home Playlist" link={Routes.HomePlaylist.List.path} icon={faListUl} />
              {/* <NavItem title="Push Notification" link="#" icon={faBell} /> */}
              <NavItem title="Radio" link={Routes.Radio.List.path} icon={faListAlt} />
              <NavItem title="Banner" link={Routes.Banner.List.path} icon={faChalkboard} />
              {/* <NavItem title="Settings" link="#" icon={faCog} /> */}
              <NavItem title="3dvista VR Room" link={Routes.ExternalVideo.List.path} icon={faPhotoVideo} />
              <NavItem title="Settings" link={Routes.Setting.List.path} icon={faServer} />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
