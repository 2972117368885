import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from "../routes";

const AuthUser = () => {
    const history = useHistory();

    const getToken = () =>{
        try {
            const tokenString = sessionStorage.getItem('token');
            if(localStorage.getItem('token')) return JSON.parse(localStorage.getItem('token'));
            return JSON.parse(tokenString);
        } catch (err) {
            return undefined;
        }
    }

    const getUser = () =>{
        try {
            const userString = sessionStorage.getItem('user');
            if(localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
            return JSON.parse(userString);
        } catch (err) {
            return undefined;
        }
    }

    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,token,rememberMe) =>{
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));

        if(rememberMe){
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('user',JSON.stringify(user));
        }

        setToken(token);
        setUser(user);
        history.push(Routes.Index.path);
    }

    const logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        history.push(Routes.Signin.path);
    }

    const http = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    });
    return {
        saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}

export default AuthUser;