import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Modal } from '@themesberg/react-bootstrap';
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";

const Settings = () => {
  const { http, getToken } = AuthUser();
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [androidVersionCode, setAndroidVersionCode] = useState('');
  const [androidBuildNumber, setAndroidBuildNumber] = useState('');
  const [iosVersionCode, setIosVersionCode] = useState('');
  const [iosBuildNumber, setIosBuildNumber] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!getToken()) {
      window.location.href = "/signin"; // Redirect to Signin page if no token
    }

    // Fetch existing settings from API on page load
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    http.get(Apis.settings.get)
      .then(res => {
        const { maintenance_mode, android_version_code, android_build_number, ios_version_code, ios_build_number } = res.data.data;
        setMaintenanceMode(maintenance_mode == '1');
        setAndroidVersionCode(android_version_code);
        setAndroidBuildNumber(android_build_number);
        setIosVersionCode(ios_version_code);
        setIosBuildNumber(ios_build_number);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      });
  };

  const saveSettings = () => {
    setLoading(true);
    setError(null);

    const payload = {
      maintenance_mode: maintenanceMode,
      android_version_code: androidVersionCode,
      android_build_number: androidBuildNumber,
      ios_version_code: iosVersionCode,
      ios_build_number: iosBuildNumber
    };

    http.post(Apis.settings.update, payload)
      .then(res => {
        alert("Settings updated successfully!");
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      });
  };

  return (
    <div className="container">
      <h4>Settings</h4>
      {error && <ErrorMsg errors={error} />}
      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="maintenanceMode">
              <Form.Label>Maintenance Mode</Form.Label>
              <Form.Check 
                type="switch"
                label={maintenanceMode ? "ON" : "OFF"}
                checked={maintenanceMode}
                onChange={(e) => setMaintenanceMode(e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="androidVersionCode">
              <Form.Label>Android Version Code</Form.Label>
              <Form.Control 
                type="text" 
                value={androidVersionCode} 
                onChange={(e) => setAndroidVersionCode(e.target.value)} 
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="androidBuildNumber">
              <Form.Label>Android Build Number</Form.Label>
              <Form.Control 
                type="text" 
                value={androidBuildNumber} 
                onChange={(e) => setAndroidBuildNumber(e.target.value)} 
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="iosVersionCode">
              <Form.Label>iOS Version Code</Form.Label>
              <Form.Control 
                type="text" 
                value={iosVersionCode} 
                onChange={(e) => setIosVersionCode(e.target.value)} 
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="iosBuildNumber">
              <Form.Label>iOS Build Number</Form.Label>
              <Form.Control 
                type="text" 
                value={iosBuildNumber} 
                onChange={(e) => setIosBuildNumber(e.target.value)} 
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" onClick={saveSettings} disabled={loading}>
          {loading ? "Saving..." : "Save Settings"}
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
