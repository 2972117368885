import React, { useState, useRef, useMemo, useCallback } from "react";
import Table from "../../Table";
import { Button, Dropdown, Modal, Nav } from '@themesberg/react-bootstrap';
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye, faFileExport, faList, faPencilAlt, faPlus, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setListData, checkRow, unCheckRow } from "../../state/action-creators/index";
import CsvDownloader from 'react-csv-downloader';

const List = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.listData);  
  const rowIds = useSelector(state => state.rowIds);

  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const setRowIdsToState = (e) => {
    if (e.target.checked) {
      dispatch(checkRow(e.target.value));
    } else {
      dispatch(unCheckRow(e.target.value));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "key",
        Cell: ({ row: { original } }) => (
          <>
            <input type="checkbox" className="form-check-input" name="row[]" value={ original.id } onChange={(e) => setRowIdsToState(e)} style={{ cursor: 'pointer' }} />            
          </>            
        ),
        checkAll: true
      },
      {
        Header: "Code",
        accessor: "code",
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Expire Date",
        accessor: "expire_date",
        Cell: ({ row: { original } }) => (
          <>
            { original.expire_date ? original.expire_date.slice(0, 10) : <em>(not set)</em> }            
          </>            
        ),
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Days Validity",
        accessor: "validity",
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        Cell: ({ row: { original } }) => (
          <>
            { original.is_active ? <span className="text-success" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={ faCheckCircle } /></span> : <span className="text-danger" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={ faTimesCircle } /></span> }            
          </>            
        ),
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ row: { original } }) => (
          <>
            { original.created_at ? original.created_at.slice(0, 10) : <em>(not set)</em> }            
          </>            
        ),
        isSorted: true,
        hasSearch: true
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span title="View" style={{ cursor: 'pointer', marginRight: '4px' }} onClick={() => viewCoupon(original)}><FontAwesomeIcon icon={ faEye } /></span>
            <Link to={`${ Routes.Coupon.Update.path.slice(0, 14) }/${ original.id }`}><span title="Update" style={{ cursor: 'pointer', marginRight: '4px' }}><FontAwesomeIcon icon={ faPencilAlt } /></span></Link>
            <span title="Delete" style={{ cursor: 'pointer' }} onClick={ () => deleteCoupon(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span>            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showViewCoupon, setShowViewCoupon] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const csvColumns = [
    {
      id: 'code',
      displayName: 'Code'
    },
    {
      id: 'expire_date',
      displayName: 'Expire Date'
    },
    {
      id: 'validity',
      displayName: 'Days Validity'
    },
    {
      id: 'is_active',
      displayName: 'Is Active'
    },
    {
      id: 'created_at',
      displayName: 'Created At'
    }
  ];
  const csvData = data ? data.map(obj => ({
                                          code: obj.code,
                                          expire_date: obj.expire_date,
                                          validity: obj.validity,
                                          is_active: obj.is_active,
                                          created_at: obj.created_at
                                        })
                        ) : null;

  const handleViewCouponClose = () => {
    setCouponData(null);
    setShowViewCoupon(false);
  }

  const viewCoupon = (data) => {
    setCouponData(data);
    setShowViewCoupon(true);
  }

  const deleteCoupon = (id) => {
    const resp = window.confirm("Do you want to delete this coupon?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.coupon.delete, {coupon_uuid: id})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    }
  }

  const deleteAll = () => {
    if (rowIds.length === 0) {
      setDeleteError(["Please select a coupon to proceed!"]);
      return false;
    }
    const resp = window.confirm("Do you want to delete selected coupons?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.coupon.deleteAll, {coupon_uuids: rowIds})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    } else {
      setDeleteError(null);
    }
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.coupon.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText && colName) {
      apiString += `&search_keyword=${searchText}&search_column=${colName}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        dispatch(setListData(res.data.data.data));
        sessionStorage.setItem("listData", JSON.stringify(res.data.data.data));
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">          
          <div className="col-sm-6 mt-3">
            <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> COUPON <span style={{ fontSize: '12px', opacity: 0.5 }}>Index</span></h6>
          </div>
          <div className="col-sm-6 text-right mt-3">
            <CsvDownloader
              filename="grid-export"
              extension=".csv"
              wrapColumnChar=""
              columns={ csvColumns }
              datas={ csvData }
              disabled={ csvData ? false : true }
            >
              <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faFileExport } /></span> Export to CSV</button>
            </CsvDownloader>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mt-2">
            <Link to={ Routes.Coupon.Create.path }><button title="Create" className="btn cust-button me-2" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faPlus } /></span></button></Link>
            <Link to={ Routes.Coupon.BulkCreate.path }><button title="Create In Bulk" className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faPlusCircle } /></span></button></Link>
            <Dropdown as={Nav.Item} className="d-inline-block">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <button className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }}>ACTIONS</button>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <span role="button" onClick={ deleteAll }>
                    <FontAwesomeIcon icon={faTrashAlt} className="text-danger me-2" /> Delete All
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-sm-6 text-right mt-2">
            
          </div>
        </div>        
        {
            error && <ErrorMsg errors={error} />
        }
        {
            deleteError && <ErrorMsg errors={deleteError} />
        }
        {
            deleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>      

      <Modal as={Modal.Dialog} centered show={ showViewCoupon } onHide={ handleViewCouponClose }>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Coupon Details</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={ handleViewCouponClose } />
        </Modal.Header>
        <Modal.Body>
          {
            couponData &&
            <div className="table-responsive">
              <table className="table table-light table-bordered table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Code</td>
                    <td>{ couponData.code }</td>
                  </tr>
                  <tr>
                    <td>Is Active</td>
                    <td>{ couponData.is_active }</td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>{ couponData.created_at }</td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>{ couponData.updated_at }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {
            couponData &&
            <Link to={`${ Routes.Coupon.Update.path.slice(0, 14) }/${ couponData.id }`}>
              <button className="btn cust-button" style={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>EDIT</button>
            </Link>
          }
          <button className="btn btn-default" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ handleViewCouponClose }>CLOSE</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default List;