import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Home from './Home';
import Signin from './Signin';
import ChangePassword from './ChangePassword';
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";
import Forbidden from './Forbidden';

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

// Menu
import Index from "./Index";

import CategoryList from "./Category/List";
import CategoryCreate from './Category/Create';
import CategoryUpdate from './Category/Update';

import ArtistList from "./Artist/List";
import ArtistCreate from './Artist/Create';
import ArtistUpdate from './Artist/Update';

import HomePlaylistyList from "./HomePlaylist/List";
import HomePlaylistCreate from './HomePlaylist/Create';
import HomePlaylistUpdate from './HomePlaylist/Update';

import SongList from "./Song/List";
import SongCreate from './Song/Create';
import SongUpdate from './Song/Update';

import PlaylistList from "./Playlist/List";
import PlaylistCreate from './Playlist/Create';
import PlaylistUpdate from './Playlist/Update';

import BannerList from "./Banner/List";
import BannerCreate from './Banner/Create';
import BannerUpdate from './Banner/Update';

import SongDurationList from "./SongDuration/List";

import RadioList from "./Radio/List";
import RadioCreate from './Radio/Create';
import AccountDeletionPage from './AccountDeletionPage';

import CouponList from "./Coupon/List";
import CouponCreate from './Coupon/Create';
import CouponUpdate from './Coupon/Update';
import CouponBulkCreate from './Coupon/BulkCreate';

import ExternalVideoList from "./ExternalVideo/List";
import ExternalVideoCreate from './ExternalVideo/Create';

import SettingPage from './Setting/List';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => {  
  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Home.path} component={Home} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.ChangePassword.path} component={ChangePassword} />
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
      <RouteWithLoader exact path={Routes.Forbidden.path} component={Forbidden} />
      <RouteWithLoader exact path={Routes.AccountDeletion.path} component={AccountDeletionPage} />

      <RouteWithSidebar exact path={Routes.Index.path} component={Index} />
      
      <RouteWithSidebar exact path={Routes.Category.List.path} component={CategoryList} />
      <RouteWithSidebar exact path={Routes.Category.Create.path} component={CategoryCreate} />
      <RouteWithSidebar exact path={Routes.Category.Update.path} component={CategoryUpdate} />

      <RouteWithSidebar exact path={Routes.Artist.List.path} component={ArtistList} />
      <RouteWithSidebar exact path={Routes.Artist.Create.path} component={ArtistCreate} />
      <RouteWithSidebar exact path={Routes.Artist.Update.path} component={ArtistUpdate} />
      
      <RouteWithSidebar exact path={Routes.HomePlaylist.List.path} component={HomePlaylistyList} />
      <RouteWithSidebar exact path={Routes.HomePlaylist.Create.path} component={HomePlaylistCreate} />
      <RouteWithSidebar exact path={Routes.HomePlaylist.Update.path} component={HomePlaylistUpdate} />

      <RouteWithSidebar exact path={Routes.Song.List.path} component={SongList} />
      <RouteWithSidebar exact path={Routes.Song.Create.path} component={SongCreate} />
      <RouteWithSidebar exact path={Routes.Song.Update.path} component={SongUpdate} />

      <RouteWithSidebar exact path={Routes.Playlist.List.path} component={PlaylistList} />
      <RouteWithSidebar exact path={Routes.Playlist.Create.path} component={PlaylistCreate} />
      <RouteWithSidebar exact path={Routes.Playlist.Update.path} component={PlaylistUpdate} />

      <RouteWithSidebar exact path={Routes.Banner.List.path} component={BannerList} />
      <RouteWithSidebar exact path={Routes.Banner.Create.path} component={BannerCreate} />
      <RouteWithSidebar exact path={Routes.Banner.Update.path} component={BannerUpdate} />

      <RouteWithSidebar exact path={Routes.SongDuration.List.path} component={SongDurationList} />


      <RouteWithSidebar exact path={Routes.Radio.List.path} component={RadioList} />
      <RouteWithSidebar exact path={Routes.Radio.Create.path} component={RadioCreate} />

      <RouteWithSidebar exact path={Routes.Coupon.List.path} component={CouponList} />
      <RouteWithSidebar exact path={Routes.Coupon.Create.path} component={CouponCreate} />
      <RouteWithSidebar exact path={Routes.Coupon.Update.path} component={CouponUpdate} />
      <RouteWithSidebar exact path={Routes.Coupon.BulkCreate.path} component={CouponBulkCreate} />

      <RouteWithSidebar exact path={Routes.ExternalVideo.List.path} component={ExternalVideoList} />
      <RouteWithSidebar exact path={Routes.ExternalVideo.Create.path} component={ExternalVideoCreate} />


      <RouteWithSidebar exact path={Routes.Setting.List.path} component={SettingPage} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
}
