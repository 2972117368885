import React, { useState, useRef, useMemo, useCallback } from "react";
import Table from "../../Table";
import { Button, Dropdown, Modal, Nav } from '@themesberg/react-bootstrap';
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCheckCircle, faEye, faList, faPencilAlt, faPlus, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setListData, checkRow, unCheckRow } from "../../state/action-creators/index";

const List = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.listData);  
  const rowIds = useSelector(state => state.rowIds);

  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const setRowIdsToState = (e) => {
    if (e.target.checked) {
      dispatch(checkRow(e.target.value));
    } else {
      dispatch(unCheckRow(e.target.value));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "key",
        Cell: ({ row: { original } }) => (
          <>
            <input type="checkbox" className="form-check-input" name="row[]" value={ original.id } onChange={(e) => setRowIdsToState(e)} style={{ cursor: 'pointer' }} />            
          </>            
        ),
        checkAll: false
      },
      {
        Header: "Track Title",
        accessor: "name",
        isSorted: false,
        hasSearch: false
      },
      {
        Header: "	Img Thumb",
        accessor: "img_thumb",
        Cell: ({ row: { original } }) => (
          <>
            { original.img_thumb && <img src={original.img_thumb} width="100" alt="Img Thumb" /> }            
          </>            
        ),
        isSorted: false,
        hasSearch: false
      },
      {
        Header: "Song File",
        accessor: "song_url",
        Cell: ({ row: { original } }) => (
          <>
            { original.song_url && <a href={original.song_url} target="_blank"><b>Link</b></a> }            
          </>            
        ),
        isSorted: false,
        hasSearch: false
      },
      {
        Header: "	Index",
        accessor: "index",
        isSorted: false,
        hasSearch: false
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span title="Delete" style={{ cursor: 'pointer' }} onClick={ () => deleteBanner(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span> &nbsp;
            <span title="Move Up" style={{ cursor: 'pointer', marginRight: '4px' }} onClick={() => moveUP(original.id)}><FontAwesomeIcon icon={ faArrowUp } /></span> &nbsp;
            <span title="Move Down" style={{ cursor: 'pointer', marginRight: '4px' }} onClick={() => moveDown(original.id)}><FontAwesomeIcon icon={ faArrowDown } /></span>                            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showViewBanner, setShowViewBanner] = useState(false);
  const [bannerData, setBannerData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null); 

  const handleViewBannerClose = () => {
    setBannerData(null);
    setShowViewBanner(false);
  }

  const viewHomePlaylist = (data) => {
    setBannerData(data);
    setShowViewBanner(true);
  }

  const deleteBanner = (id) => {
    const resp = window.confirm("Do you want to delete this Radio Song?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.radio.delete, {radio_song_uuid: id})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    }
  }

  const moveUP = (id) => {
    const resp = window.confirm("Do you want to move up this Radio Song?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.radio.moveup, {radio_song_uuid: id})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    }
  }

  const moveDown = (id) => {
    const resp = window.confirm("Do you want to move down this Radio Song?");
    if (resp) {
      setDeleteError(null);
      setDeleteLoading(true);
      http.post(Apis.radio.movedown, {radio_song_uuid: id})
      .then(res => {
        setDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setDeleteError(errorMsg);
        } else {
          setDeleteError([err.response.data.message]);
        }
      });
    }
  }

  

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.radio.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText && colName) {
      apiString += `&search_keyword=${searchText}&search_column=${colName}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        dispatch(setListData(res.data.data.data));
        sessionStorage.setItem("listData", JSON.stringify(res.data.data.data));
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">          
          <div className="col-sm-6 mt-3">
            <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> RADIO <span style={{ fontSize: '12px', opacity: 0.5 }}>Index</span></h6>
          </div>
          <div className="row">
            <div className="col-sm-6 mt-2">
              <Link to={ Routes.Radio.Create.path }><button title="Create" className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faPlus } /></span></button></Link>              
            </div>
            <div className="col-sm-6 text-right mt-2">
              
            </div>
          </div>
          <div className="col-sm-6 text-right mt-3">
            
          </div>
        </div>        
        {
            error && <ErrorMsg errors={error} />
        }
        {
            deleteError && <ErrorMsg errors={deleteError} />
        }
        {
            deleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>      

      <Modal as={Modal.Dialog} centered show={ showViewBanner } onHide={ handleViewBannerClose }>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Banner Details</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={ handleViewBannerClose } />
        </Modal.Header>
        <Modal.Body>
          {
            bannerData &&
            <div className="table-responsive">
              <table className="table table-light table-bordered table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Title</td>
                    <td>{ bannerData.title }</td>
                  </tr>
                  <tr>
                    <td>Image</td>
                    <td>{ <img src={bannerData.image} width="200" alt="Img Thumb" /> }</td>
                  </tr>                  
                  <tr>
                    <td>Playlist Name</td>
                    <td>{ bannerData.playlist ? bannerData.playlist.name : '' }</td>
                  </tr>
                  <tr>
                    <td>Active</td>
                    <td>{ bannerData.is_active ? "Yes" : "No" }</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td>{ bannerData.created_at }</td>
                  </tr>
                  <tr>
                    <td>Updated</td>
                    <td>{ bannerData.updated_at }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {
            bannerData &&
            <Link to={`${ Routes.Banner.Update.path.slice(0, 14) }/${ bannerData.id }`}>
              <button className="btn cust-button" style={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>EDIT</button>
            </Link>
          }
          <button className="btn btn-default" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ handleViewBannerClose }>CLOSE</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default List;