
import React from "react";

export default (props) => {
  return (
    <div>
      <footer className="footer section py-5">
        { (new Date()).getFullYear() } © PeerTracks Inc.
      </footer>
    </div>
  );
};
